.container {
  width: 290px;
  background-color: #272A3F;
  transition: width 200ms ease-in-out;
}

.containerCollapsed {
  width: 110px;
  background-color: #272A3F;
  transition: width 200ms ease-in-out;
}

.topContainer {
  padding: 26px 26px 26px 38px;
  border-bottom: 1px solid #36394F;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuIcon {
  color: #FFFFFF;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.menuCollapsedIcon {
  color: #FFFFFF;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  transform: rotateZ(180deg);
}

.menuIcon:hover, .menuCollapsedIcon:hover {
  opacity: 0.7;
}

.categories {
  padding: 24px 38px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.categoriesCollapsed {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
}

.category {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.categoryTitle {
  color: #F9F9F9;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}

.linkActive {
  margin-bottom: 5px;
  padding: 8px 10px;
  color: #F2F2F2;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 5px;
}

.link {
  margin-bottom: 5px;
  padding: 8px 10px;
  color: #6E718A;
  display: flex;
  align-items: center;
}

.linkCollapsed {
  padding: 8px 0;
}

.linkIcon {
  margin-right: 14px;
}

.linkCollapsedIcon {
  margin: auto;
}
