.container {
  box-sizing: border-box;
  padding: 21px 24px 21px 30px;
  height: 119px;
  border-bottom: 1px solid #36394F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #33364C;
  gap: 16px;
}

.rightActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lang {
  font-weight: 600;
}

.userDropdown {
  box-sizing: border-box;
  width: 247px;
  height: 73px;
  padding: 14px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #45485E;
}

.userInfo {
  margin-left: 10px;
}

.userName {
  color: #F2F2F2;
  font-weight: 600;
}

.userType {
  color: #6E718A;
  font-size: 14px;
}
