.search {
  background: #36394F;
  border: 0;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100%;
  padding: 14px 22px;
  font-size: 16px;
  color: #FFF;
  outline: 1px solid transparent;
  transition: outline 200ms ease-in-out;
}

.search:focus, .search:active {
  outline: 1px solid #BB6BD9;
}

.product-image {
  width: 67px;
  height: 67px;
  padding: 10px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
  background: #FFF;
  color: #585d88;
}

.product-name {
  margin-left: 29px;
  font-size: 20px;
  font-weight: 600;
}

.ag-root-wrapper {
  border: 0 !important;
  background: transparent !important;
  overflow: hidden;
}

.ag-header {
  border: 0 !important;
  background: transparent !important;
}

.ag-header-container {
  background: #272A3F;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.ag-header-cell {
  color: #6E718A;
  font-weight: 600;
}

.ag-header-cell-resize:after {
  height: 46% !important;
  top: calc(50% - 23%) !important;
  background-color: #36394F !important;
}

.ag-center-cols-container:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 20px;
  background: #272A3F;
}

.ag-row {
  border: 0 !important;
  color: #FFF;
  background: transparent !important;
  border-radius: 20px 20px 30px 30px;
  cursor: pointer;
  overflow: hidden;
}

.ag-row-hover .ag-cell {
  background-color: #505470 !important;
}

.ag-row-hover:before {
  display: none !important;
}

.ag-cell {
  border: 0 !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #36394F !important;
  transition: background-color 200ms ease-in-out;
  color: #FFF;
  height: calc(100% - 8px);
}

.ag-dnd-ghost {
  background-color: #36394F !important;
}

.ag-dnd-ghost-icon, .ag-dnd-ghost-label {
  color: #FFF !important;
}

.ag-header-cell.ag-header-cell-moving {
  background-color: #36394F !important;
}

.ag-overlay-wrapper {
  color: #FFF;
}

.ag-overlay-loading-wrapper {
  background-color: #36394F !important;
  border-radius: 20px;
  overflow: hidden;
}

.filters {
  max-width: 80vw;
  padding: 25px 50px;
  background-color: #EFF0F8;
  border-radius: 20px;
}

.filters > h1 {
  color: #272A3F;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 26px;
}

.filters > div {
  display: flex;
  gap: 34px;
}

.filtersBtn {
  margin-left: auto;
  background: #9B51E0;
  width: 142px;
  height: 44px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.filtersBtn:hover {
  opacity: 0.7;
}

.applyBtn {
  margin-left: auto;
  margin-top: 20px;
  width: 121px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 128px;
  background: linear-gradient(180deg, #CB1CB1 0%, #E010C2 100%);
  border-radius: 52px;
  color: #FFF;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
}

.applyBtn:hover {
  opacity: 0.7;
}
