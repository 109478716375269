.selectWrapper {
  width: 290px;
  z-index: 10000;
}

.label {
  font-size: 16px;
  font-weight: 600;
  color: #36394F;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.select {
  position: relative;
  background-color: #FFF;
  padding: 15px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
}

.optionsList {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  border: 1px solid #272A3F;
  background-color: #FFF;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 134px;
  overflow: auto;
}

.option {
  padding: 15px 23px;
  transition: all 200ms ease-in-out;
}

.option:hover {
  background-color: #585d88;
  color: #FFF;
}

.icon {
  transition: all 200ms linear;
}

.iconInverted {
  transition: all 200ms linear;
  transform: rotate(180deg);
}
